import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const isValidTelephoneNumber = (input, countryCode = 'ZA') => {
    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCode);
        return phoneUtil.isValidNumber(number);
    }
    catch (err) {
    }

    return false;
};

export const normalizeTelephoneNumber = (input, countryCode = 'ZA') => {
    if (!isValidTelephoneNumber(input, countryCode))
        return undefined;

    const number = phoneUtil.parseAndKeepRawInput(input, countryCode);
    return phoneUtil.format(number, PhoneNumberFormat.E164)
};